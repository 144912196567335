import "styles.scss";
import AppComponent from "./app.vue";
import Vue from "vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faCoffee);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

var Application = new Vue(AppComponent);

// Not going to use a CMS yet
// import Butter from 'buttercms';
// export const butter = Butter('98b4ccdaa0ba78430c493d7fb1fca6d6a7396e90');

window.initialize = function () {
  console.time("MOUNT");
  Application.$mount("#app");
  console.timeEnd("MOUNT");
};
document.addEventListener("DOMContentLoaded", function onDocumentReady() {
  initialize();
});



