<template lang="pug">
.app
  nav#navigation
    ul
      li#logo
        span#smart
            a(href="#") Smart
        span#piggy
            a(href="#") Piggy
      li.nav-links
        a(href="#") Home
      li.nav-links: a(href="#features") Features
      li.nav-links: a(href="https://blog.smartpiggy.io") Blog
      li.nav-links: a(href="#subscribe") Subscribe
      li
      //- li#menu-container
      //-   div.top-bar
      //-   div.middle-bar
      //-   div.bottom-bar
  main
    router-view
  footer
    //- ul
    //-   li: a(href="") About Us
    //-   li: a(href="#") Contact Us
    //-   li: a(href="#") Support
    //- ul
    //-   li: a(href="#") Social Media
    //-   li: a(href="#") Blog
    //-   li: a(href="#") Careers
    //- ul
    //-   li: a(href="#") Privacy Policy
    //-   li: a(href="#") Legal
    ul
      li.social-icons
        a.i.fab.fa-facebook-f(href="https://www.facebook.com/SmartPiggy-920719618117492/")
        a.i.fab.fa-instagram(href="https://www.instagram.com/smartpiggy_app/")
        a.i.fab.fa-twitter(href="https://twitter.com/smart_piggy")
        a.i.fab.fa-pinterest-p(href="https://www.pinterest.com/smart_piggy/")

</template>

<script>
import router from "./router.js";
export default {
  router
}
</script>

<style lang="scss">
@import "./styles/_variables.scss";
@media (max-width: 600px) {
  html, body, * {
    font-size: 65%;
  }
}
  html, body, * {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 0.45px;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
  }

  #logo {
    font-size: 3rem;
    font-weight: 500;
    transition: $subtle-transition;
    #smart {
      a {
        color: #009E92;
      }
    }
    #piggy {
      a {
        color: whitesmoke;
      }
    }
  }

  nav {
    display: flex;
    position: fixed;
    justify-content: center;
    top: 0;
    background-color: rgba(0, 0, 0, .35);
    width: 100%;
    height: 6rem;
    z-index: 1000;
    box-sizing:border-box;
    overflow: hidden;
    a {
      color: whitesmoke;
      text-decoration: none;
      font-weight: 500;
    }
    @media (max-width: 900px) {
      .nav-links {
        display: none;
      }
      #menu-container {
        width: 30px;
        height: 30px;
        div {
          width: 100%;
          height: 5px;
          margin: 4px;
        }
      }
    }

    ul {
      display: flex;
      width: 65%;
      align-items: center;
      justify-content: space-between;
      padding: 0 3rem 0 3rem;
      @media (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 600px) {
        padding: 0 2rem 0 2rem;
      }
      li {
        font-size: 1.7rem;
        @include nav-effects();
      }
    }
  }

  #menu-container {
    color: transparent;
    opacity: 0;
    width: 50px;
    height: 50px;
    div {
      background-color: #009E92;
      border-radius: 100px;
      width: 100%;
      height: 7px;
      margin: 7px;
    }
  }

  footer {
    display: flex;
    position: static;
    background: url('./desk-laptop.jpg') 0 0 no-repeat;
    background-size: cover;
    background-position: center;
    color: rgb(0, 132, 141);
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 300px;
    box-sizing:border-box;
    overflow:hidden;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      background-color: rgba(0, 0, 0, .8);
      height: 100%;
      width: 100%;
      li {
        padding: 2rem;
        font-size: 1rem;
        svg {
          font-size: 2rem;
        }
        a {
          color: whitesmoke;
          text-decoration: none;
          font-weight: lighter;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .social-icons a {
    box-sizing: border-box;
    color: whitesmoke;
    font-size: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    &:hover {
      text-decoration: none;
    }
    @media (max-width: 900px){
      display: none;
    }
  }
</style>
