import Vue from "vue";
import VueRouter from "vue-router";

import HomePage from "./pages/home.vue";
import BlogPage from "./pages/blog.vue";

Vue.use(VueRouter);


const routes = [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/BlogPage',
    component: BlogPage
  }
];

const router = new VueRouter({
  routes: routes
});


export default router;
